.chakra-modal__content-container {
  z-index: 6010 !important;
}

.chakra-modal__overlay {
  z-index: 6010 !important;
}

/** 필요할 때 되살리기 **/
/* .chakra-modal__content {
  width: 260px !important;
} */
